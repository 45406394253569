export const TOPIC_NAMES = {
  sample: 'sample',
  sample2: 'sample2',
  ADD_ENGAGEMENT: 'ADD_ENGAGEMENT',
  CONTAINER_TAB_OPENED: 'CONTAINER_TAB_OPENED',
  DISPATCHED_EVENT: 'DISPATCHED_EVENT',
  UPDATE_ASSOCIATIONS: 'UPDATE_ASSOCIATIONS',
  OBJECT_CREATED: 'OBJECT_CREATED',
  OBJECT_DELETED: 'OBJECT_DELETED',
  OBJECTS_DELETED: 'OBJECTS_DELETED',
  UPDATE_OBJECT: 'UPDATE_OBJECT',
  UPDATE_OBJECTS: 'UPDATE_OBJECTS',
  PREVIEW_OBJECT: 'PREVIEW_OBJECT',
  PREVIEW_ENGAGEMENT: 'PREVIEW_ENGAGEMENT',
  CLOSE_PREVIEW_OBJECT: 'CLOSE_PREVIEW_OBJECT',
  OPEN_ACTIVITY_CREATOR: 'OPEN_ACTIVITY_CREATOR',
  CLOSE_ACTIVITY_CREATOR: 'CLOSE_ACTIVITY_CREATOR',
  ACTIVITY_CREATOR_OPENED: 'ACTIVITY_CREATOR_OPENED',
  ACTIVITY_CREATOR_CLOSED: 'ACTIVITY_CREATOR_CLOSED',
  OPEN_CALL_WIDGET: 'OPEN_CALL_WIDGET',
  OPEN_OBJECT_BUILDER: 'OPEN_OBJECT_BUILDER',
  OPEN_UPDATE_ASSOCIATION_MODAL: 'OPEN_UPDATE_ASSOCIATION_MODAL',
  OPEN_IFRAME_MODAL: 'OPEN_IFRAME_MODAL',
  IFRAME_MODAL_CLOSED: 'IFRAME_MODAL_CLOSED',
  SHOW_ALERT: 'SHOW_ALERT',
  OPEN_PLAYBOOK: 'OPEN_PLAYBOOK',
  SEQUENCE_ENROLLMENT: 'SEQUENCE_ENROLLMENT',
  SEQUENCE_UNENROLL: 'SEQUENCE_UNENROLL',
  COMMENTS_UPDATED: 'COMMENTS_UPDATED',
  PINNED_ENGAGEMENT_UPDATED: 'PINNED_ENGAGEMENT_UPDATED',
  INTERNAL_REGISTER_COMMAND_SUBSCRIBED: 'INTERNAL_REGISTER_COMMAND_SUBSCRIBED',
  INTERNAL_REGISTER_COMMAND_UNSUBSCRIBED: 'INTERNAL_REGISTER_COMMAND_UNSUBSCRIBED',
  OPEN_PROPERTY_HISTORY: 'OPEN_PROPERTY_HISTORY',
  OPEN_ENRICHMENT_PROPERTIES_PANEL: 'OPEN_ENRICHMENT_PROPERTIES_PANEL',
  CLOSE_ENRICHMENT_PROPERTIES_PANEL: 'CLOSE_ENRICHMENT_PROPERTIES_PANEL',
  OPEN_CARD_USER_CUSTOMIZATION: 'OPEN_CARD_USER_CUSTOMIZATION',
  OPEN_DEAL_SCORE_HISTORY: 'OPEN_DEAL_SCORE_HISTORY',
  UPDATE_CARD_CONFIGURATION: 'UPDATE_CARD_CONFIGURATION',
  OPEN_COLLAB_SIDEBAR: 'OPEN_COLLAB_SIDEBAR',
  UPDATE_APPROVAL_CARD: 'UPDATE_APPROVAL_CARD',
  UPDATE_RECORD_ATTACHMENTS: 'UPDATE_RECORD_ATTACHMENTS',
  OPEN_ATTACHMENTS_MODAL: 'OPEN_ATTACHMENTS_MODAL',
  OPEN_ATTACHMENTS_FILE_MANAGER: 'OPEN_ATTACHMENTS_FILE_MANAGER',
  OPEN_ATTACHMENTS_REMOVE_MODAL: 'OPEN_ATTACHMENTS_REMOVE_MODAL',
  RECORD_ATTACHMENTS_REMOVED: 'RECORD_ATTACHMENTS_REMOVED',
  OPEN_MANAGE_RECORD_ACCESS_PANEL: 'OPEN_MANAGE_RECORD_ACCESS_PANEL',
  OPEN_SHARE_ENGAGEMENT_ACCESS_MODAL: 'OPEN_SHARE_ENGAGEMENT_ACCESS_MODAL',
  OPEN_ACTIVITY_TIMELINE_SIDEBAR: 'OPEN_ACTIVITY_TIMELINE_SIDEBAR',
  CLOSE_ACTIVITY_TIMELINE_SIDEBAR: 'CLOSE_ACTIVITY_TIMELINE_SIDEBAR',
  OPEN_DEAL_SPLITS_SIDEBAR: 'OPEN_DEAL_SPLITS_SIDEBAR',
  OPEN_SCORE_HISTORY: 'OPEN_SCORE_HISTORY',
  OPEN_RECENT_PRODUCTS: 'OPEN_RECENT_PRODUCTS',
  OPEN_DATA_ENTRY_ASSISTANT: 'OPEN_DATA_ENTRY_ASSISTANT',
  OPEN_DEAL_REGISTRATION_SUMMARY: 'OPEN_DEAL_REGISTRATION_SUMMARY',
  CLOSE_DEAL_REGISTRATION_SUMMARY: 'CLOSE_DEAL_REGISTRATION_SUMMARY',
  DATA_ENTRY_ASSISTANT_CLOSED: 'DATA_ENTRY_ASSISTANT_CLOSED',
  OPEN_WORKFLOW_ENROLLMENT_MODAL: 'OPEN_WORKFLOW_ENROLLMENT_MODAL',
  OPEN_MULTI_ACCOUNT_PANEL: 'OPEN_MULTI_ACCOUNT_PANEL',
  OPEN_DELETE_ENGAGEMENT_MODAL: 'OPEN_DELETE_ENGAGEMENT_MODAL',
  OPEN_DELETE_SCHEDULED_EMAIL_MODAL: 'OPEN_DELETE_SCHEDULED_EMAIL_MODAL',
  OPEN_REPLACE_PINNED_ENGAGEMENT_MODAL: 'OPEN_REPLACE_PINNED_ENGAGEMENT_MODAL',
  COMMAND_HANDLER_PING: 'COMMAND_HANDLER_PING',
  COMMAND_HANDLER_PONG: 'COMMAND_HANDLER_PONG'
};