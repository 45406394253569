import { getPerformanceAPI } from './internal/fromWindow';
import performanceMeasurementInvariant from './measurements/performanceMeasurementInvariant';
import markExists from './internal/markExists';
import isBeginningOfTime from './internal/isBeginningOfTime';
import getMarkerName from './internal/getMarkerName';
import { Metrics } from './internal/Metrics';
const measureBetween = (name, start, end) => {
  const perfAPI = getPerformanceAPI();
  if (!perfAPI) {
    return undefined;
  }
  if ((markExists(start) || isBeginningOfTime(start)) && markExists(end)) {
    const startMark = isBeginningOfTime(start) ? undefined : getMarkerName(start);
    const endMark = getMarkerName(end);
    perfAPI.measure(name, startMark, endMark);
    const measure = perfAPI.getEntriesByName(name);
    if (measure && measure.length > 0) {
      return measure[0].duration;
    }
    return undefined;
  }
  return undefined;
};
const waitAndMeasure = (name, start, end) => {
  const measurementPromise = new Promise((resolve, reject) => {
    const perfAPI = getPerformanceAPI();
    if (!perfAPI) {
      reject(new Error('Performance API not available'));
      return;
    }
    const ref = setInterval(() => {
      try {
        const duration = measureBetween(name, start, end);
        if (duration !== undefined) {
          clearInterval(ref);
          resolve(duration);
        }
      } catch (ex) {
        clearInterval(ref);
        resolve(undefined);
      }
    }, 200);
  });
  return measurementPromise;
};
const measure = (measurement, metricsAdditionalDimensions = {}) => {
  performanceMeasurementInvariant(measurement);
  const {
    name,
    metricName,
    start,
    end
  } = measurement;
  const measurePromise = waitAndMeasure(name, start, end);
  measurePromise.then(duration => {
    if (duration !== undefined) {
      Metrics.timer(metricName || measurement.name, Object.assign({}, metricsAdditionalDimensions, {
        start: start.name || 'beginning of time or now',
        end: end.name || 'end'
      })).update(duration);
    }
    return duration;
  }).catch(() => {
    // no-op;
  });
};
export default measure;