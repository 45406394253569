export const REPLACE_STATE = 'historyreplacestate';
export class ReplaceStateEvent extends Event {
  constructor() {
    super(REPLACE_STATE);
  }
}
export const getParentWindow = () => {
  const isInIframe = window.self !== window.top;
  return isInIframe ? window.top : window;
};

/**
 * Extract a given query string parameter from the URL
 */
function replaceState(...args) {
  getParentWindow().history.replaceState(...args);
  // Dispatching a custom event when we change the query params
  // this allow us to sync query string states across different components
  getParentWindow().dispatchEvent(new ReplaceStateEvent());
}
export const getQueryStringParameter = name => {
  const searchParams = new URLSearchParams(getParentWindow().location.search);
  return searchParams.get(name);
};
export const deleteQueryStringParameter = name => {
  const searchParams = new URLSearchParams(getParentWindow().location.search);
  searchParams.delete(name);
  replaceState({}, '', `?${searchParams.toString()}`);
  return searchParams.toString();
};
export const setQueryStringParameter = (name, value) => {
  const searchParams = new URLSearchParams(getParentWindow().location.search);
  searchParams.set(name, value);
  replaceState({}, '', `?${searchParams.toString()}`);
};