import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { OPEN_OBJECT_BUILDER_HANDLER } from './handlers/ObjectBuilderHandler';
import { OPEN_PROPERTY_HISTORY_HANDLER } from './handlers/OpenPropertyHistoryHandler';
import { PREVIEW_OBJECT_HANDLER } from './handlers/PreviewObjectHandler';
import { OPEN_DEAL_REGISTRATION_SUMMARY_HANDLER } from './handlers/OpenDealRegistrationSummaryHandler';
import { PREVIEW_ENGAGEMENT_HANDLER } from './handlers/PreviewEngagementHandler';
import { createShowAlertHandler } from './handlers/ShowAlertHandler';
import { OPEN_CARD_USER_CUSTOMIZATION_HANDLER } from './handlers/OpenPropertiesCardUserCustomizationHandler';
import { OPEN_ENRICHMENT_PROPERTIES_PANEL_HANDLER } from './handlers/OpenEnrichmentPropertiesPanelHandler';
import { OPEN_UPDATE_ASSOCIATION_MODAL_HANDLER } from './handlers/OpenUpdateAssociationModalHandler';
import { OPEN_IFRAME_MODAL_HANDLER } from './handlers/OpenIframeModalHandler';
import { OPEN_ATTACHMENTS_MODAL_HANDLER } from './handlers/OpenAttachmentsModalHandler';
import { OPEN_COLLAB_SIDEBAR_HANDLER } from './handlers/OpenCollabSidebarHandler';
import { OPEN_DEAL_SCORE_HISTORY_HANDLER } from './handlers/OpenDealScoreHistoryHandler';
import { OPEN_MANAGE_RECORD_ACCESS_PANEL_HANDLER } from './handlers/OpenManageRecordAccessPanelHandler';
import { OPEN_SHARE_ENGAGEMENT_ACCESS_MODAL_HANDLER } from './handlers/OpenShareEngagementAccessModalHandler';
import { OPEN_ATTACHMENTS_FILE_MANAGER_HANDLER } from './handlers/OpenAttachmentsFileManagerHandler';
import { OPEN_ATTACHMENTS_REMOVE_MODAL_HANDLER } from './handlers/OpenAttachmentsRemoveModalHandler';
import { OPEN_ACTIVITY_TIMELINE_SIDEBAR_HANDLER } from './handlers/OpenActivityTimelineSidebarHandler';
import { OPEN_ACTIVITY_CREATOR_WINDOW_HANDLER } from './handlers/OpenActivityCreatorWindowHandler';
import { OPEN_DEAL_SPLITS_SIDEBAR_HANDLER } from './handlers/OpenDealSplitsSidebarHandler';
import { OPEN_SCORE_HISTORY_HANDLER } from './handlers/OpenScoreHistoryHandler';
import { OPEN_RECENT_PRODUCTS_HANDLER } from './handlers/OpenRecentProductsHandler';
import { OPEN_DATA_ENTRY_ASSISTANT_HANDLER } from './handlers/OpenDataEntryAssistantHandler';
import { OPEN_WORKFLOW_ENROLLMENT_MODAL_HANDLER } from './handlers/OpenWorkflowEnrollmentModalHandler';
import { OPEN_MULTI_ACCOUNT_PANEL_HANDLER } from './handlers/OpenMultiAccountPanelHandler';
import { OPEN_DELETE_ENGAGEMENT_MODAL_HANDLER } from './handlers/OpenDeleteEngagementModalHandler';
import { OPEN_DELETE_SCHEDULED_EMAIL_MODAL_HANDLER } from './handlers/OpenDeleteScheduledEmailModalHandler';
import { OPEN_REPLACE_PINNED_ENGAGEMENT_MODAL_HANDLER } from './handlers/OpenReplacePinnedEngagementModalHandler';
import { PING_PONG_HANDLER } from './handlers/PingPongHandler';
export function createDefaultCrmCommandHandlers({
  disableDefaultHandlers,
  floatingAlertStoreInstance,
  // If migrating a message to the command handler you can check gates here before adding the handler
  // That will prevent the handler from subscribing to the command message when gated.
  gates: __gates
}) {
  const DEFAULT_HANDLERS = {
    [TOPIC_NAMES.OPEN_ATTACHMENTS_MODAL]: OPEN_ATTACHMENTS_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_ATTACHMENTS_FILE_MANAGER]: OPEN_ATTACHMENTS_FILE_MANAGER_HANDLER,
    [TOPIC_NAMES.OPEN_ATTACHMENTS_REMOVE_MODAL]: OPEN_ATTACHMENTS_REMOVE_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_CARD_USER_CUSTOMIZATION]: OPEN_CARD_USER_CUSTOMIZATION_HANDLER,
    [TOPIC_NAMES.OPEN_ENRICHMENT_PROPERTIES_PANEL]: OPEN_ENRICHMENT_PROPERTIES_PANEL_HANDLER,
    [TOPIC_NAMES.OPEN_COLLAB_SIDEBAR]: OPEN_COLLAB_SIDEBAR_HANDLER,
    [TOPIC_NAMES.OPEN_DEAL_SCORE_HISTORY]: OPEN_DEAL_SCORE_HISTORY_HANDLER,
    [TOPIC_NAMES.OPEN_IFRAME_MODAL]: OPEN_IFRAME_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_MANAGE_RECORD_ACCESS_PANEL]: OPEN_MANAGE_RECORD_ACCESS_PANEL_HANDLER,
    [TOPIC_NAMES.OPEN_SHARE_ENGAGEMENT_ACCESS_MODAL]: OPEN_SHARE_ENGAGEMENT_ACCESS_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_OBJECT_BUILDER]: OPEN_OBJECT_BUILDER_HANDLER,
    [TOPIC_NAMES.OPEN_PROPERTY_HISTORY]: OPEN_PROPERTY_HISTORY_HANDLER,
    [TOPIC_NAMES.OPEN_UPDATE_ASSOCIATION_MODAL]: OPEN_UPDATE_ASSOCIATION_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_DEAL_REGISTRATION_SUMMARY]: OPEN_DEAL_REGISTRATION_SUMMARY_HANDLER,
    [TOPIC_NAMES.PREVIEW_OBJECT]: PREVIEW_OBJECT_HANDLER,
    [TOPIC_NAMES.PREVIEW_ENGAGEMENT]: PREVIEW_ENGAGEMENT_HANDLER,
    [TOPIC_NAMES.SHOW_ALERT]: createShowAlertHandler({
      floatingAlertStoreInstance
    }),
    [TOPIC_NAMES.OPEN_ACTIVITY_TIMELINE_SIDEBAR]: OPEN_ACTIVITY_TIMELINE_SIDEBAR_HANDLER,
    [TOPIC_NAMES.OPEN_ACTIVITY_CREATOR]: OPEN_ACTIVITY_CREATOR_WINDOW_HANDLER,
    [TOPIC_NAMES.OPEN_DEAL_SPLITS_SIDEBAR]: OPEN_DEAL_SPLITS_SIDEBAR_HANDLER,
    [TOPIC_NAMES.OPEN_SCORE_HISTORY]: OPEN_SCORE_HISTORY_HANDLER,
    [TOPIC_NAMES.OPEN_RECENT_PRODUCTS]: OPEN_RECENT_PRODUCTS_HANDLER,
    [TOPIC_NAMES.OPEN_DATA_ENTRY_ASSISTANT]: OPEN_DATA_ENTRY_ASSISTANT_HANDLER,
    [TOPIC_NAMES.OPEN_WORKFLOW_ENROLLMENT_MODAL]: OPEN_WORKFLOW_ENROLLMENT_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_MULTI_ACCOUNT_PANEL]: OPEN_MULTI_ACCOUNT_PANEL_HANDLER,
    [TOPIC_NAMES.OPEN_DELETE_ENGAGEMENT_MODAL]: OPEN_DELETE_ENGAGEMENT_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_DELETE_SCHEDULED_EMAIL_MODAL]: OPEN_DELETE_SCHEDULED_EMAIL_MODAL_HANDLER,
    [TOPIC_NAMES.OPEN_REPLACE_PINNED_ENGAGEMENT_MODAL]: OPEN_REPLACE_PINNED_ENGAGEMENT_MODAL_HANDLER,
    [TOPIC_NAMES.COMMAND_HANDLER_PING]: PING_PONG_HANDLER
  };

  // Filter out default handlers which have been disabled via props
  disableDefaultHandlers.forEach(topicName => {
    if (DEFAULT_HANDLERS[topicName]) {
      delete DEFAULT_HANDLERS[topicName];
    }
  });
  return DEFAULT_HANDLERS;
}