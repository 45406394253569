import enviro from 'enviro';
const GROWTH_PAYMENTS = 'GROWTH_PAYMENTS';
const GROWTH_PAYMENTS_DEBUG = `${GROWTH_PAYMENTS}_DEBUG`;
export function isGrowthPaymentsDebugEnabled() {
  if (enviro.isProd()) {
    return false;
  }
  try {
    return localStorage.getItem(GROWTH_PAYMENTS_DEBUG) === 'true';
  } catch (error) {
    /* noop */
  }
  return false;
}
export default isGrowthPaymentsDebugEnabled();