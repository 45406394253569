'use es6';

/*
 * WARNING: Do not add more imports into this file! It is used in a quick-fetch, and imports
 * will bloat the quick-fetch bundle, slowing down the app.
 */
import { parseObjectTypeIdFromPath } from './parseObjectTypeIdFromPath';
import { getPageType } from './getPageType';
import { getPipelineId } from './getPipelineId';
import { getViewId } from './getViewId';
import { doQuickFetches } from './doQuickFetches';
import QuickFetchError from './quickFetchError';
import { setQuickFetchErrorOnWindow } from './quickFetchErrorsOnWindow';
const afterAuth = window.quickFetch.afterAuth;
const pathname = window.location.pathname;
const portalId = window.quickFetch.getPortalId();
const objectTypeId = parseObjectTypeIdFromPath(pathname);
const viewId = getViewId(pathname, objectTypeId);
const pipelineId = getPipelineId(objectTypeId, portalId);
const pageType = getPageType(pathname, objectTypeId);
const makeRestRequest = (name, url, options) => new Promise((resolve, reject) => {
  window.quickFetch.makeEarlyRequest(name, Object.assign({
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET',
    url: window.quickFetch.getApiUrl(url),
    whenFinished: resolve,
    onError: (xhttp, message) => {
      reject(new QuickFetchError({
        message,
        xhttp
      }));
    }
  }, options));
}).catch(error => {
  setQuickFetchErrorOnWindow(error);
  return null;
});
doQuickFetches({
  objectTypeId,
  viewId,
  makeRestRequest,
  afterAuth,
  setQuickFetchErrorOnWindow,
  pipelineId,
  pageType,
  redirect: url => window.location.href = url
});