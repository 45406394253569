import debug from 'growth-payments-core/debug/console';
import { getQueryStringParameter } from 'growth-payments-core/query-string/utils';
export const COMMERCE_HUB_USER_GUIDE = 'commerce-hub-user-guide';
export const navigateTo = (url, openInNewWindow = false) => {
  try {
    if (openInNewWindow) {
      window.open(url, '_blank');
    } else {
      const isInIframe = window.self !== window.top;
      if (isInIframe) {
        window.top.location.href = url;
      } else {
        window.location.href = url;
      }
    }
  } catch (e) {
    debug.error('An error occurred while navigating', e);
  }
};
export const navigateToWithQueryParams = (url, openInNewWindow = false) => {
  const urlWithParams = url + window.location.search;
  navigateTo(urlWithParams, openInNewWindow);
};
export const getIsComingFromUserGuide = () => window.location.href.indexOf('via=user-guide') > -1;
export function isComingFromCommerceHubUserGuide() {
  return getQueryStringParameter('via') === COMMERCE_HUB_USER_GUIDE;
}