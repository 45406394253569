import { useCallback } from 'react';
import memoizeOne from 'react-utils/memoizeOne';
import { useHttpClient } from '../../client/HttpClientContext';
import { getPropertyDefinitions } from '../../propertyDefinitions/getPropertyDefinitions';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { keyBy } from '../../utils/keyBy';
const keyByName = memoizeOne(properties => keyBy(properties, ({
  property: {
    name
  }
}) => name,
// HACK: These types are identical in reality — the generated type has `fieldType` as string,
// but it is constrained by BE validation to the same set of values as this local type.
({
  property
}) => property));
export const useProperties = (objectTypeId, options = {
  skip: false
}) => {
  const httpClient = useHttpClient();
  const getDefinitions = useCallback(() => {
    return getPropertyDefinitions({
      objectTypeId,
      httpClient
    }).then(keyByName);
  }, [httpClient, objectTypeId]);
  return useAsyncFunction(getDefinitions, options);
};