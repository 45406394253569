import { useQuery, gql } from '@apollo/client';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, ObjectTypeFromIds } from 'customer-data-objects/constants/ObjectTypeIds';
// @ts-expect-error untyped
import { canBETCreate } from 'customer-data-objects/permissions/BETPermissions';
import { useScopes } from '../../auth/hooks/useScopes';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useMemo } from 'react';
const OBJECT_PERMISSIONS_QUERY = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"ObjectPermissionsQuery\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypePermissions\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"currentUserCanCreate\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "ObjectPermissionsQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "objectTypePermissions"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "objectTypeId"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "currentUserCanCreate"
            }
          }]
        }
      }]
    }
  }]
});
export const useCurrentUserCanCreate = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const {
    data,
    loading,
    error
  } = useQuery(OBJECT_PERMISSIONS_QUERY, {
    variables: {
      objectTypeId
    }
  });
  const scopes = useScopes();
  const currentUserCanCreate = useMemo(() => {
    var _data$objectTypePermi;
    const currentUserCanCreateBackendValue = Boolean(data === null || data === void 0 || (_data$objectTypePermi = data.objectTypePermissions) === null || _data$objectTypePermi === void 0 ? void 0 : _data$objectTypePermi.currentUserCanCreate);
    if (objectTypeId === CONTACT_TYPE_ID || objectTypeId === COMPANY_TYPE_ID) {
      //the Boolean() is here because canBETCreate is untyped
      return Boolean(currentUserCanCreateBackendValue && canBETCreate(scopes, ObjectTypeFromIds[objectTypeId]));
    }
    return currentUserCanCreateBackendValue;
  }, [data, objectTypeId, scopes]);
  return useMemo(() => ({
    data: currentUserCanCreate,
    loading,
    error
  }), [currentUserCanCreate, loading, error]);
};