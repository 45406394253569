import * as PropertyFieldTypes from 'customer-data-objects/property/PropertyFieldTypes';
import * as PropertyTypes from 'customer-data-objects/property/PropertyTypes';
import { DURATION, UNFORMATTED, PERCENTAGE, PROBABILITY } from './NumberDisplayHint';
import { TIME_SINCE, TIME_UNTIL } from './DateDisplayHint';
import { currencyPricePrefix } from 'customer-data-objects/lineItem/PropertyNames';
import { CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, SUBSCRIPTION_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, CAMPAIGN_TYPE_ID, INVOICE_TYPE_ID, LEAD_TYPE_ID, MARKETING_EVENT_TYPE_ID, PRODUCT_TYPE_ID, LINE_ITEM_TYPE_ID, denormalizeTypeId, isObjectTypeId, CALL_TYPE_ID, COMMUNICATION_TYPE_ID, EMAIL_TYPE_ID, MEETING_EVENT_TYPE_ID, NOTE_TYPE_ID, TASK_TYPE_ID, APPOINTMENT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { CONTACT, COMPANY, DEAL, TICKET, VISIT, MEETING_EVENT } from 'customer-data-objects/constants/ObjectTypes';
import { COMPANY_LATEST_DATA_1, COMPANY_LATEST_DATA_2, CONTACT_LATEST_DATA_1, CONTACT_LATEST_DATA_2, DATA_1, DATA_2, DEAL_LATEST_DATA_1, DEAL_LATEST_DATA_2, LATEST_DATA_2, VISIT_DATA_1, VISIT_DATA_2 } from 'customer-data-objects/record/AnalyticsSourceIdentifier';
import { isPropertySourceUserId as isPropertySourceUserIdValue } from 'customer-data-objects/property/PropertySourceIdentifier';
import { OWNER } from './ExternalOptionTypes';
import * as ExternalOptionsReferenceTypes from '../externalOptions/ExternalOptionsReferenceTypes';
import { isKnownGuid } from './knownGuidUtils';
import PropertyNameToReferenceType from 'customer-data-objects/property/PropertyNameToReferenceType';
// NOTE: Do not add any immutable/transmute dependencies to this file, it is
// meant to be a lightweight utility file that all projects can import to
// identify properties so it should have no deps other than direct imports from
// customer-data-objects
export const ProfilePropertyBlocklist = ['_inbounddbio.importid_', 'associatedcompanyid', 'associations.company', 'associations.contact', 'dealstage.probability', 'formSubmissions.formId', 'ilsListMemberships.listId', 'listMemberships.listId'];
export const isMarketingEventDateTime = (property, objectTypeId) => objectTypeId === MARKETING_EVENT_TYPE_ID && (property.name === 'hs_start_datetime' || property.name === 'hs_end_datetime');
export const isEngagementDateTime = (property, objectTypeId) => [CALL_TYPE_ID, COMMUNICATION_TYPE_ID, EMAIL_TYPE_ID, MEETING_EVENT_TYPE_ID, NOTE_TYPE_ID, TASK_TYPE_ID].includes(objectTypeId) && ['hs_timestamp', 'hs_meeting_start_time'].includes(property.name);
const isAppointmentDateTime = (property, objectTypeId) => {
  return objectTypeId === APPOINTMENT_TYPE_ID && (property.name === 'hs_appointment_start' || property.name === 'hs_appointment_end');
};
export const isVisibleProperty = ({
  hidden,
  name,
  hubspotDefined
}) => {
  return !hidden && !(ProfilePropertyBlocklist.includes(name) && hubspotDefined);
};
export function isReadOnly(property) {
  return property.readOnlyValue || property.calculated || !isVisibleProperty(property) || property.name === 'file_upload_';
}
export function isTextarea(property) {
  return property && property.type === PropertyTypes.STRING && (property.fieldType === PropertyFieldTypes.TEXTAREA || property.name === 'description');
}
export function isSingleLineText(property) {
  return property && property.type === PropertyTypes.STRING && !isTextarea(property) && !['firstname', 'lastname', 'phone'].includes(property.name);
}

/**
 * Returns true for properties whose type is DATE_TIME or DATE. DATE_TIME
 * properties have a time component and are rendered in the user's timezone,
 * while DATEs do not have a time component (it is always 00:00) and are
 * rendered in UTC.
 *
 * NOTE: This helper checks both types because the CRM historically uses the same
 * input for both. The time component of DATE_TIMEs is typically hidden from the UI,
 * and users cannot create custom DATE_TIMEs.
 *
 * See https://product.hubteam.com/docs/crm-properties/values/date.html for more information.
 */
export function isDate(property) {
  return property && [PropertyTypes.DATE_TIME, PropertyTypes.DATE].includes(property.type);
}

/**
 * Returns true for properties whose type is DATE_TIME, meaning they track a date
 * with time information. DATE_TIME properties are always rendered in the user's timezone,
 * and the time component is generally hidden from the UI (aside from a few exceptions
 * such as the table).
 *
 * NOTE: isDateTime was added to support the table, which does special relative
 * rendering for DATE_TIME properties.
 *
 * See https://product.hubteam.com/docs/crm-properties/values/date.html for more information.
 */
export function isDateTime(property) {
  return property && property.type === PropertyTypes.DATE_TIME;
}

/**
 * Returns true for properties whose type is DATE_TIME, but which should appear
 * in the UI as just a date. No time should be shown. Values for these properties
 * should be interpreted in the user's timezone.
 *
 * This applies to all HubSpot-defined datetime properties for historical reasons.
 * Many default properties (such as Contact's "Create Date" or Deal's "Close Date")
 * are defined as `type=datetime`, but users have been trained to think of them
 * as "just" dates because they have always behaved as "just" dates in the CRM.
 *
 * Eventually we should make this distinction explicit, such as by introducing a
 * `fieldType=datetime` and migrating custom datetime properties. In the
 * meantime we allowlist custom, user-defined datetime properties to
 * behave like "real" datetimes in the UI. HubSpot-defined datetimes should
 * continue to behave like dates.
 *
 * See https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/896
 * See https://docs.google.com/document/d/166HKssw-vDjffAMDiFxqIRS9ZDsVbWQVRL55tXHznUQ/edit
 */
export const isDateTimeWithHiddenTime = (property, objectTypeId) => {
  // Allowlist of Hubspot-defined datetime properties that should render the time
  if ([isMarketingEventDateTime, isEngagementDateTime, isAppointmentDateTime].some(checkCondition => checkCondition(property, objectTypeId))) {
    return false;
  }
  return isDateTime(property) && property.hubspotDefined;
};
export function isCurrency(property) {
  return property && property.showCurrencySymbol && property.type === PropertyTypes.NUMBER;
}
export function isFormattedNumber(property) {
  return property && property.type === PropertyTypes.NUMBER && property.numberDisplayHint !== UNFORMATTED;
}
export function isNumber(property) {
  return property && property.type === PropertyTypes.NUMBER;
}
export function isPhoneNumber(property) {
  return property && property.fieldType === PropertyFieldTypes.PHONE_NUMBER;
}
export function isEnum(property) {
  return property && [PropertyTypes.ENUMERATION, PropertyTypes.BOOLEAN].includes(property.type);
}
export function isMultienum(property) {
  return property && property.type === PropertyTypes.ENUMERATION && (property.isMultiValued || property.fieldType === PropertyFieldTypes.CHECKBOX);
}

// Identifies whether a number ought to be displayed as a percent AND is stored as a whole number percent (i.e. 50 displayed as 50%)
export function isPercentWholeNumber(property) {
  return property && property.name === 'hs_predictivecontactscore_v2';
}

// Identifies whether a number ought to be displayed as a percent AND is stored as a decimal percent (i.e. 0.5 displayed as 50%)
export function isPercent(property) {
  return property && property.type === PropertyTypes.NUMBER && property.numberDisplayHint === PERCENTAGE;
}

// Identifies whether a number ought to be displayed as a percent AND is stored as a decimal percent AND must be in the range 0-1
// (i.e. 0.5 displayed as 50%, should not be less than 0.0 or greater than 1.0)
export function isProbability(property) {
  return property && property.type === PropertyTypes.NUMBER && (property.numberDisplayHint === PROBABILITY ||
  // Grandfather in legacy `percentage` properties which should be treated as
  // probabilities (input values limited to the range 0-1) until their property
  // definitions can be updated to have `numberDisplayHint=probability`.
  // See https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/721
  property.name === 'hs_forecast_probability' ||
  // DEAL
  property.name === 'hs_deal_stage_probability' ||
  // DEAL and DEAL_SPLIT
  property.name === 'hs_deal_forecast_probability') // DEAL_SPLIT
  ;
}
export function isDuration(property) {
  if (!property) {
    return property;
  }
  const isDefaultDuration = property.hubspotDefined && property.name && (property.name === 'time_to_first_agent_reply' || property.name === 'time_to_close' ||
  // This startsWith check is overly permissive and should ideally be
  // removed - for now, we're excluding relative time properties from the
  // condition. This check was originally added for stage calculated
  // properties which are currently being deprecated.
  property.name.startsWith('hs_time_in_') && !(property.dateDisplayHint === TIME_SINCE || property.dateDisplayHint === TIME_UNTIL));
  const isStandardDuration = property.type === PropertyTypes.NUMBER && property.numberDisplayHint === DURATION;
  return isDefaultDuration || isStandardDuration;
}
export function isUser(property) {
  return property && property.hubspotDefined && (property.name === 'hs_created_by' || property.name === 'hs_created_by_user_id' || property.name === 'hs_updated_by_user_id');
}
export function isMultiCurrencyPrice(property) {
  return isCurrency(property) && property.hubspotDefined && property.name && property.name.startsWith(currencyPricePrefix);
}
export function isAppId(property) {
  return property && property.hubspotDefined && property.name === 'hs_app_id';
}
export function isHtml(property) {
  return property && property.type === PropertyTypes.STRING && property.fieldType === PropertyFieldTypes.HTML;
}

// HACK: The default property names are hard-coded because we do not have access to
// the object type definition here. Customers cannot make properties prefixed with
// hs_ and those are the defaults set when enabling custom object pipelines,
//  so this should be relatively safe until we're in a place where we can rely on the typeDef.
export function isPipelineProperty(property, objectTypeId) {
  if (!property || !property.hubspotDefined || !property.externalOptions) {
    return false;
  }
  switch (objectTypeId) {
    case DEAL_TYPE_ID:
      {
        return property.name === 'pipeline';
      }
    case TICKET_TYPE_ID:
    case LEAD_TYPE_ID:
    default:
      {
        return property.name === 'hs_pipeline';
      }
  }
}
export function isPipelineStageProperty(property, objectTypeId) {
  if (!property || !property.hubspotDefined || !property.externalOptions) {
    return false;
  }
  switch (objectTypeId) {
    case CONTACT_TYPE_ID:
    case COMPANY_TYPE_ID:
      {
        return property.name === 'lifecyclestage';
      }
    case DEAL_TYPE_ID:
      {
        return property.name === 'dealstage';
      }
    case TICKET_TYPE_ID:
    case LEAD_TYPE_ID:
    default:
      {
        return property.name === 'hs_pipeline_stage';
      }
  }
}

/**
 * @return {boolean} Whether the given property/value pair represent a property source userId.
 * Values of the `hs_analytics_source_data_2` and `hs_latest_source_data_2` properties, as
 * well as other property sources, may use the format `userId:123` to indicate that the source
 * of a property change was a HubSpot user.
 */
export function isPropertySourceUserId(property, value) {
  return property && property.hubspotDefined && (property.name === DATA_2 || property.name === LATEST_DATA_2) && isPropertySourceUserIdValue(value);
}
export function hasUniqueValue(property) {
  return property && property.hasUniqueValue;
}
const getReferenceObjectType = (property, objectType) => property.referencedObjectType || PropertyNameToReferenceType[objectType] && PropertyNameToReferenceType[objectType][property.name] || null;
export const isContactEmail = (property, objectType) => objectType === CONTACT && property.name === 'email';

// placeholder function to identify email properties
// Currently this is used only for portal 53. The portal check is added in customer-data-properties as we don't have access to portalId here.
// TODO: Remove hard-coded email property names once we add an identifier (probably 'validationHint') in property definition
export const isEmail = (property, objectType) => objectType === DEAL && property.name === 'primary_point_of_contact_email';
export const isDealPipeline = (property, objectType) => objectType === DEAL && property.name === 'pipeline';
export const isDealStage = (property, objectType) => objectType === DEAL && property.name === 'dealstage';
export const isDomain = (property, objectType) => objectType === COMPANY && property.name === 'domain';
export const isOwner = (property, objectType) => getReferenceObjectType(property, objectType) === OWNER;
export const isPersona = (property, objectType) => objectType === CONTACT && property.name === 'hs_persona';
export const isTicketPipeline = (property, objectType) => objectType === TICKET && property.name === 'hs_pipeline';
export const isTicketStage = (property, objectType) => objectType === TICKET && property.name === 'hs_pipeline_stage';
export const isCompany = (property, objectType) => objectType === CONTACT && property.name === 'associatedcompanyid' || objectType === COMPANY && property.name === 'hs_parent_company_id';
export const isCreateCloseDate = (property, objectType) => objectType === DEAL && (property.name === 'createdate' || property.name === 'closedate') || objectType === TICKET && (property.name === 'createdate' || property.name === 'closed_date');
export const isFileUpload = property => property.name === 'file_upload_';
export const isMultiCurrencyCurrencyCode = (property, objectType) => objectType === DEAL && property.name === 'deal_currency_code' || objectType === CAMPAIGN_TYPE_ID && property.name === 'hs_currency_code';
export const isName = (property, objectType) => objectType === CONTACT && ['firstname', 'lastname'].includes(property.name);
export const isPriority = property => property.hubspotDefined && (property.name === 'hs_ticket_priority' || property.name === 'hs_task_priority' || property.name === 'hs_priority' || property.name === 'hs_lead_label');
export const isSubscriptionStatus = (property, objectTypeId) => objectTypeId === SUBSCRIPTION_TYPE_ID && property.name === 'hs_status';
export const isMarketingEventImportStatus = (property, objectTypeId) => {
  return objectTypeId === MARKETING_EVENT_TYPE_ID && property.name === 'hs_import_status';
};
export const isInvoiceDueDate = (property, objectTypeId) => objectTypeId === INVOICE_TYPE_ID && property.name === 'hs_due_date';
export const isInvoiceStatus = (property, objectTypeId) => objectTypeId === INVOICE_TYPE_ID && property.name === 'hs_invoice_status';
export const isPaymentStatus = (property, objectType) => objectType === COMMERCE_PAYMENT_TYPE_ID && property.name === 'hs_latest_status';
export const isPaymentInitialAmount = (property, objectType) => objectType === COMMERCE_PAYMENT_TYPE_ID && property.name === 'hs_initial_amount';
export const isPaymentProcessor = (property, objectType) => objectType === COMMERCE_PAYMENT_TYPE_ID && property.name === 'hs_processor_type';
export const isFile = property => property.fieldType === PropertyFieldTypes.FILE;

// Product-specific properties

export const isProductUrl = (property, objectTypeId) => objectTypeId === PRODUCT_TYPE_ID && property.name === 'hs_url';
export function isProductUnitPrice(property, objectTypeId) {
  return objectTypeId === PRODUCT_TYPE_ID && property.name === 'price';
}
export function isProductUnitCost(property, objectTypeId) {
  return objectTypeId === PRODUCT_TYPE_ID && property.name === 'hs_cost_of_goods_sold';
}

/**
 * @deprecated use isTermBillingPeriod
 * */
export function isMonthPeriod(property, objectTypeId) {
  return property.name === 'hs_recurring_billing_period' && (objectTypeId ? objectTypeId === PRODUCT_TYPE_ID || objectTypeId === LINE_ITEM_TYPE_ID : true);
}
export function isTermBillingPeriod(property, objectTypeId) {
  return property.name === 'hs_recurring_billing_period' && (objectTypeId ? objectTypeId === PRODUCT_TYPE_ID || objectTypeId === LINE_ITEM_TYPE_ID : true);
}
export function isBillingFrequency(property, objectTypeId) {
  return property.name === 'recurringbillingfrequency' && (objectTypeId === PRODUCT_TYPE_ID || objectTypeId === LINE_ITEM_TYPE_ID);
}

/**
 * Properties that can contain GUID constants as values, keyed by object type
 *
 * @constants
 * @private
 */
const KNOWN_GUID_PROPERTIES = {
  [VISIT]: [VISIT_DATA_1, VISIT_DATA_2],
  [CONTACT]: [DATA_1, DATA_2, CONTACT_LATEST_DATA_1, CONTACT_LATEST_DATA_2],
  [DEAL]: [DATA_1, DATA_2, DEAL_LATEST_DATA_1, DEAL_LATEST_DATA_2],
  [COMPANY]: [DATA_1, DATA_2, COMPANY_LATEST_DATA_1, COMPANY_LATEST_DATA_2]
};

/**
 * Check whether given property/value contains a known GUID.
 * If so, the value should be translated using `getGuidLabel`.
 *
 * @param {Property} property property containing value
 * @param {string} objectType object type containing the property in question
 * @param {string} value current value to check
 * @returns {boolean} Whether the given property/value is a known GUID
 */
export const isGuidPropertyWithKnownGuid = (property, objectType, value) => {
  const guidPropertiesForType = KNOWN_GUID_PROPERTIES[objectType];
  if (!guidPropertiesForType) {
    return false;
  }
  return guidPropertiesForType.includes(property.name) && !!value && isKnownGuid(value);
};
export const isCheckbox = property => property.fieldType === PropertyFieldTypes.BOOLEAN_CHECKBOX;

// This util is a holdover while we migrate existing properties onto external
// options. Right now there are some properties that are valid external options
// but that aren't supported by the service so we need to provide a util that
// apps can use to figure out which properties to send to reference-resolvers-lite
// and which properties they should continue to resolve how they were before.
export const isPropertySupportedByExternalOptions = ({
  property,
  objectTypeId
}) => {
  // It is not yet safe to trust that if a property definition has an
  // `externalOptionsReferenceType` set, that type will be supported by
  // external-options API. Once that guarantee is enforced, then we can remove
  // this allowlist and assume that any definition with an
  // `externalOptionsReferenceType` is supported.
  //
  // For now we are allowlisting all known `ReferenceTypes` (with a few notable
  // exceptions mentioned below that require testing first).
  //
  // See https://git.hubteam.com/HubSpot/ExternalOptions/blob/master/ExternalOptionsCore/src/main/java/com/hubspot/externaloptions/core/ReferenceType.java
  // See https://git.hubteam.com/HubSpot/ExternalOptions/issues/143
  if (
  // any ReferenceType constant, unless otherwise noted
  // property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.OWNER || // used in 48 properties; need to manually verify these before enabling
  property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.TEAM ||
  // property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.MULTI_CURRENCY_CURRENCY_CODE || // used is 6 properties; need to manually verify these before enabling
  property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PERSONA || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PIPELINE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PIPELINE_STAGE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SEQUENCE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.WORKFLOW || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.BUSINESS_UNIT || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.ORIGINAL_SOURCE_1 || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CAMPAIGN || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.TASK_QUEUE ||
  // property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CRM_OBJECT || // used in 73 properties; probably accidental, CRM_OBJECT on its own w/out an object type id is not useful
  property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.USER || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CHANNEL_INSTANCE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.GENERIC_CHANNEL || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.TRACKED_TERM || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SEQUENCE_WITH_PERMISSIONS || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PIPELINE_WITH_PERMISSIONS || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PIPELINE_STAGE_WITH_PERMISSIONS || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CRM_OBJECT_TAG || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.STATIC_INBOUND_DB_LIST || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.OPTIMIZATION_TYPE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CALL_DISPOSITION || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.AD_ACCOUNT || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SALES_DOCUMENT || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.MARKETING_SMS_ROOT_MIC || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.MEETING_OUTCOME || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SYNC_ACCOUNT || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.FORM ||
  // known CrmObject type names (XO treats these as CRM_OBJECT/{objectType})
  property.externalOptionsReferenceType === MEETING_EVENT || property.externalOptionsReferenceType === 'CHATFLOW' || property.externalOptionsReferenceType === 'CONVERSATION_INBOX' || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PERMISSION_SET || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SEAT || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SOURCE_LABEL || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.ACTIVITY_TYPE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CONTENT || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.FILE_ID_OR_URL || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.FLOW_ACTION_TYPE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.COMMERCE_PAYMENT_METHOD || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.FLOW_ACTION_TYPE_GROUP || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.PLAYBOOK || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.FORECAST_TYPE || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.USER_MANAGER || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.COMMERCE_PRODUCT_TAX_CATEGORY || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.MARKETING_EMAIL || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.CONTACT_EMAIL_ADDRESS || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.INBOUND_DB_LIST || property.externalOptionsReferenceType === ExternalOptionsReferenceTypes.SCHEDULING_PAGE ||
  // any object type id (XO treats these as CRM_OBJECT/{objectType})
  isObjectTypeId(property.externalOptionsReferenceType)) {
    return true;
  }
  if (isPipelineStageProperty(property, objectTypeId)) {
    return true;
  }
  if (isPipelineProperty(property, objectTypeId)) {
    return true;
  }

  // Unfortunately the CONTACT `hs_persona` property has no `externalOptions` or `externalOptionsReferenceType` set
  // NOTE: RRL's `getReferenceTypeFromProperty` is aware of this, but CDO has no dep on RRL and cannot reference it
  // See https://git.hubteam.com/HubSpot/InboundDbProperties/issues/3629
  // See https://tools.hubteam.com/puma/objects/CONTACT/properties/hs_persona/details
  if (isPersona(property, denormalizeTypeId(objectTypeId))) {
    return true;
  }

  // Unfortunately the shared `hs_created_by_user_id` and `hs_updated_by_user_id` properties have no `externalOptions` or `externalOptionsReferenceType` set
  // See https://git.hubteam.com/HubSpot/InboundDbProperties/issues/3629
  // See https://tools.hubteam.com/puma/objects/CONTACT/properties/hs_created_by_user_id/details
  if (isUser(property)) {
    return true;
  }
  return false;
};