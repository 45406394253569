import { useUserInfoContext } from '../../common/context/internal/UserInfoContext';
import { CRM_LINKS_SPECULATION_GATE } from '../../Gates';
let hasSetSpeculationRulesScript = false;
export function useSetSpeculationRulesScript() {
  const userInfo = useUserInfoContext();
  if (!(userInfo !== null && userInfo !== void 0 && userInfo.gates.includes(CRM_LINKS_SPECULATION_GATE)) || !HTMLScriptElement.supports || !HTMLScriptElement.supports('speculationrules') || hasSetSpeculationRulesScript) {
    return;
  }
  const scriptEl = document.createElement('script');
  scriptEl.type = 'speculationrules';
  const specRules = {
    prerender: [{
      where: {
        selector_matches: "[data-speculation-target='crm-links-CRM_OBJECT_RECORD']"
      },
      eagerness: 'moderate'
    }]
  };
  scriptEl.textContent = JSON.stringify(specRules);
  document.body.append(scriptEl);
  hasSetSpeculationRulesScript = true;
}